<template>
    <div class="sup_content customer-list">
        <div class="sup_page_heading">
            <h2>Package Type</h2>
            <Button v-if="packageTypeAdd" icon="pi pi-plus" class="p-button-sm p-button-rounded p-button-primary" v-tooltip="'Add Package Type'" @click="packageTypeModal.addPackageTypeModal = true" />
        </div>
        <div class="sup_inner_content">
            <!--- Show Package Type Table Start --->
            <DataTable class="p-datatable" :paginator="true" :value="allPackageTypeData.data" :rows="10" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="Showing {currentPage} of {totalPages}" :rowsPerPageOptions="[10,20,50]" :rowHover="true" :loading="allPackageTypeData.loading" responsiveLayout="scroll" removableSort :filters="filters" @row-select="selectedItem($event)" @row-select-all="selectedItem($event)">
                <template #loading>
                    Loading Packages data. Please wait.
                </template>
                <template #header>
                    <div class="p-d-flex p-jc-between">
                        <div class="sup_list_action">
                            <Button v-if="packageTypeDeactivateList" :label="allPackageTypeData.activityStatus === 'active' ? 'Deactive List': 'Active List'" :icon="['pi', allPackageTypeData.activityStatus === 'active' ? 'pi-ban' : 'pi-check-circle']" :class="[allPackageTypeData.activityStatus === 'active' ? 'p-button-danger' : 'p-button-primary', 'p-mr-2']" @click="fetchAllPackageType(allPackageTypeData.activityStatus, 'packageStatus')" />
                            <Button v-if="allPackageTypeData.activityStatus !== 'active'" label="Delete All" icon="pi pi-trash" class="p-button-danger"  />
                        </div>
                        <div class="p-d-flex p-align-center">
                            <span class="p-input-icon-left sup_search_box">
                                <i class="pi pi-search" />
                                <InputText v-model="allPackageTypeData.search" placeholder="Search Package Type" @keyup.enter="fetchAllPackageType(allPackageTypeData.activityStatus, 'search')"/>
                            </span>
                            <Calendar v-model="allPackageTypeData.dateRange" dateFormat="dd.mm.yy" selectionMode="range" class="sup_button p-ml-2" inputClass="p-inputtext" placeholder="DD.MM.YY" :manualInput="false" :showIcon="true" @date-select="allPackageTypeData.dateRange[1] != null ? fetchAllPackageType(allPackageTypeData.activityStatus, 'dateRange') : ''" plceholder="abc" />
                            <Button v-if="allPackageTypeData.search !== null || allPackageTypeData.dateRange !== null" icon="pi pi-refresh" class="p-button-sm p-button-dark sup_search_clear p-ml-2" @click="fetchAllPackageType(allPackageTypeData.activityStatus, 'resetFilters')"/>
                        </div>
                    </div>
                </template>
                <template #empty>
                    No Package found.
                </template>
                <Column v-if="allPackageTypeData.activityStatus !== 'active'" selectionMode="multiple" headerStyle="width: 3rem" :exportable="false"></Column>
                <Column field="name" header="Type Name" :sortable="true"></Column>

                <Column field="status" header="Status">
                    <template #body="slotProps">
                        <span :class="['sup_item_status', slotProps.data.status ? 'sup_item_status_success' : 'sup_item_status_danger']">{{slotProps.data.status ? 'Active' : 'Inactive'}}</span>
                    </template>
                </Column>

                <Column field="createdAt" header="Created On">
                    <template #body="slotProps">
                        {{usFormatDate(slotProps.data.createdAt)}}
                        <!-- {{slotProps.data.createdAt.split("T")[0]}} {{slotProps.data.createdAt.split("T")[1].split(".")[0]}} -->
                    </template>
                </Column>

                <Column field="action" header="Actions">
                    <template #body="slotProps">
                        <Button v-if="packageTypeEdit ? slotProps.data.deletedAt === null : false" type="button" icon="pi pi-pencil" class="p-button-sm p-button-rounded p-button-primary p-mr-1" @click="editPackageType(slotProps.data)" v-tooltip="'Edit Package'" />
                        <Button v-if="packageTypeDeactivate ? slotProps.data.deletedAt === null : false" type="button" icon="pi pi-ban" class="p-button-sm p-button-rounded p-button-danger p-mr-1" v-tooltip="'Deactivate'" @click="confimationModal(slotProps.data, 'delete')" />
                        <Button v-if="packageTypeDeactivate ? slotProps.data.deletedAt !== null : false" icon="pi pi-undo" class="p-button-secondary p-button-rounded p-button-sm p-mr-1"  v-tooltip="'Restore'" @click="confimationModal(slotProps.data, 'restore')" />
                    </template>
                </Column>
            </DataTable>
            <!--- Show Package Type Table End --->
        </div>
        <!--- Add Package Type Modal Start --->
        <Dialog header="Add Package Type" v-model:visible="packageTypeModal.addPackageTypeModal" :modal="true" :style="{width: '400px'}"  @hide="closeModal()">
            <form class="sup_package_add" @submit.prevent="createPackageType()">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="p_name">Package Name*</label>
                        <InputText v-model="addEditPackageType.name" id="p_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.packageName">Name is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Add" class="btn-primary p-mr-2 sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', allPackageTypeData.addStatus === 200 ? 'success' : 'error']" v-if="allPackageTypeData.addStatus !== ''">{{allPackageTypeData.addMessage}}</div >
            </form>
        </Dialog>
        <!--- Add Package Type Modal End --->
        <!--- Edit Package Type Modal Start --->
        <Dialog v-model:visible="packageTypeModal.editPackageTypeModal" :modal="true" :style="{width: '400px'}"  @hide="closeModal()">
            <template #header>
                <span class="p-dialog-title">Edit {{packageTypeModal.editPackageTypeData.name}}</span>
            </template>
            <form class="sup_package_add" @submit.prevent="updatePackageType(packageTypeModal.editPackageTypeData.uuid)">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col-12">
                        <label for="p_name">Package Name*</label>
                        <InputText v-model="addEditPackageType.name" id="p_name" type="text" />
                        <small class="p-invalid sup_text_danger" v-if="addEditvalidationError.packageName">Name is not available.</small>
                    </div>
                </div>
                <div class="p-fluid p-d-flex sup_submit_wrap">
                    <Button type="submit" label="Add" class="btn-primary p-mr-2 sup_submit" />
                </div>
                <div :class="['sup_form_massage p-mt-3', allPackageTypeData.addStatus === 200 ? 'success' : 'error']" v-if="allPackageTypeData.addStatus !== ''">{{allPackageTypeData.addMessage}}</div >
            </form>
        </Dialog>
        <!--- Edit Package Type Modal End --->
        <!--- Deactive/Restore Confirmation Start --->
        <Dialog header="Confirmation" v-model:visible="confirmationModal.modalState" :style="{width: '350px'}" :modal="true">
            <div class="confirmation-content p-d-flex p-items-center">
                <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 1.6rem" />
                <span>Do you want to proceed?</span>
            </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="confirmationModal.modalState = false" class="p-button-text" autofocus/>
                <Button label="Yes" icon="pi pi-check" @click="deleteAndRestorePakageT(confirmationModal.modaldata.data, confirmationModal.modaldata.load), confirmationModal.modalState = false" class="p-button-primary" />
            </template>
        </Dialog>
        <!--- Deactive/Restore Confirmation End --->    
    </div>
</template>

<script>
import { packageType } from '../../../../config/appUrls';
import { apiKey } from "../../../../config/constant";
import userLogout from '../../../../helper/userLogout/userLogout';
import {currentMenuUuid} from '../../../../helper/pageActions/pageActions';
import axios from "axios";
import { useStore } from 'vuex';
import {usFormatDate } from '../../../../helper/utility/utility';
import { reactive, onBeforeMount, computed } from 'vue';

export default {
    name: 'PackageType',
    setup(){
        //Application store call
        const store = useStore();

        //Page Privilages data state
        const pagePrivilages = reactive({
            userRole: '',
            uuid: '',
            actions: ''
        });

        //Package Type data storing state
        const allPackageTypeData = reactive({
            data: null,
            total: null,
            status: null,
            loading: false,
            activityStatus: 'active',
            search: null,
            dateRange: null,
            addStatus: '',
            addMessage: '',
            editStatus: '',
            editMessage: '',
        });

        const confirmationModal = reactive({
            modalState: false,
            modaldata: '',
        });

        const packageTypeModal = reactive({
            addPackageTypeModal: false,
            editPackageTypeModal: false,
            editPackageTypeData: false
        });

        const addEditPackageType = reactive({
            name: ''
        });

        const addEditvalidationError = reactive({
            nameEmpty: false
        });

        //Coditional page action state to update UI 
        const packageTypeAdd = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.packagetypeAdd);
        const packageTypeEdit = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.packagetypeEdit);
        const packageTypeDeactivate = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.packagetypeDeactivate);
        const packageTypeDeactivateList = computed(() => pagePrivilages.userRole === 'systemAdministrator' ? true : pagePrivilages.actions.packagetypeDeactivateList);

        (async () => {
            let { userRole, menuActions } = await currentMenuUuid('PackageType');
            pagePrivilages.userRole = userRole;
            pagePrivilages.actions = menuActions;
        })();

        onBeforeMount(async() => {
            fetchAllPackageType();
        });

        const fetchAllPackageType = async (activityStatus, load) =>{
            let date = null;
            if(load === 'packageStatus'){
                allPackageTypeData.activityStatus = activityStatus === 'active' ? 'de-active' : 'active' ;
            }
            if(load === 'resetFilters'){
                allPackageTypeData.search = null;
                allPackageTypeData.dateRange = null;
            }

            if (load === 'dateRange') {
                console.log(formatDate(allPackageTypeData.dateRange[0]) + ' ' + formatDate(allPackageTypeData.dateRange[1]));
                date = formatDate(allPackageTypeData.dateRange[0]) + ' ' + formatDate(allPackageTypeData.dateRange[1]);
            }

            try{
                allPackageTypeData.loading = !allPackageTypeData.loading;
                const response = await axios.get( packageType, {
                    params: {
                        status: allPackageTypeData.activityStatus,
                        search: allPackageTypeData.search,
                        filterDt: date
                    },
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }, 
                });
                allPackageTypeData.data = response.data.data.packagesType;
                allPackageTypeData.total = response.data.data.total;
                allPackageTypeData.loading = !allPackageTypeData.loading;
            } catch(err){
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    userLogout();
                } else {
                    console.log('error', err);
                }
            }
        }

        const createPackageType = async () =>{
            if (!addEditPackageType.name) {
                addEditvalidationError.packageName = true;
            } else {
                addEditvalidationError.packageName = false;
            }

            if (!addEditvalidationError.packageName) {
                try{
                    const response = await axios.put( packageType, {
                        name: addEditPackageType.name,
                    }, { headers: {
                            apiKey: apiKey,
                            token: store.getters.adminAuthToken,
                        },
                    });
                    if(response.status === 200) {
                        allPackageTypeData.addStatus = response.data.status;
                        allPackageTypeData.addMessage = response.data.data;
                        fetchAllPackageType('active');
                        addEditPackageType.name = ''
                    }
                } catch(err){
                    console.log('err', err);
                }
            }
        }

        const editPackageType = (slotData) =>{
            packageTypeModal.editPackageTypeModal = true,
            packageTypeModal.editPackageTypeData = slotData,
            addEditPackageType.name = slotData.name
        }

        const updatePackageType = async (uuid) =>{
            try{
                const response = await axios.patch( packageType, {
                    name: addEditPackageType.name,
                }, {
                    params: {
                        uuid: uuid,
                    }, headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken,
                    },
                });
                if(response.status === 200) {
                    allPackageTypeData.addStatus = response.data.status;
                    allPackageTypeData.addMessage = 'Type updated Successfully';
                    fetchAllPackageType('active');
                    addEditPackageType.name = response.data.data.name;
                }
            } catch(err){
                console.log('err', err);
            }
        }

        const confimationModal = (sloteData, load) => {
            confirmationModal.modalState = true;
            confirmationModal.modaldata = {
                data: sloteData,
                load: load
            }
        }

        const deleteAndRestorePakageT = async (sloteData, type) => {
            try{
                const options = {
                    method: type === 'delete' ? 'delete' : 'patch',
                    url: (packageType) + (type === 'delete' ? '/delete' : '/restore'),
                    params: {
                        uuid: sloteData.uuid
                    },
                    headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                    transformRequest: [(data) => {
                        // transform the data
                        return data;
                    }]
                };
                await axios(options);
                fetchAllPackageType('active');
            } catch(err){
                console.log(err);
            }

        }

        //Filtering date format
        const formatDate = (date) => {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();

            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;

            return [year, month, day].join('-');
        }

        const closeModal = () => {
            addEditPackageType.name = '';
            allPackageTypeData.addStatus = '';
            allPackageTypeData.addMessage = '';
            allPackageTypeData.editStatus = '';
            allPackageTypeData.editMessage = '';
        }

        return{
            packageTypeAdd,
            packageTypeEdit,
            packageTypeDeactivate,
            packageTypeDeactivateList,
            allPackageTypeData,
            confirmationModal,
            packageTypeModal,
            addEditPackageType,
            addEditvalidationError,
            fetchAllPackageType,
            confimationModal,
            deleteAndRestorePakageT,
            createPackageType,
            closeModal,
            editPackageType,
            updatePackageType,
            usFormatDate
        }
    }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/style/component/superAdmin/pages/package/PackageType";
</style>